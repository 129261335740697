import "./Footer.scss";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as AwladLogo } from "assets/awlad/logo.svg";
import { ReactComponent as AlterwealthLogo } from "assets/awlad/alterwealth-logo.svg";
import { ReactComponent as BdouinLogo } from "assets/awlad/bdouin-logo.svg";
import { StoresBtn } from "elements/StoresBtn";

function Footer(props: { className?: string }) {
  const { t } = useTranslation();

  return (
    <>
      <footer className={props.className || ""}>
        <div className="logo-and-partner">
          <div className="col col1">
            <AwladLogo />
            <p>
              <Trans i18nKey="footer-desc" />
            </p>
          </div>
          <div className="col col2">
            <StoresBtn />
            <h1>{t("footer-partner")}</h1>
            <div className="partner-logo">
              <a href="https://www.alterwealth.org/" className="alterwaylogo">
                <AlterwealthLogo />
              </a>
              <BdouinLogo className="bdouinlogo" />
            </div>
          </div>
        </div>
        <div className="copyright">
          &copy;{new Date().getFullYear()}. {t("footer-copyright")}
        </div>
      </footer>
    </>
  );
}

export default Footer;
