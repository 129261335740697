import { Player } from '@lottiefiles/react-lottie-player';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
// import { registerDonation } from 'services/EmailAPI';
import './Section1.scss';

const disableSendBtn = process.env.REACT_APP_DISABLE_DONATION === '1';

function Section1() {
  const { t } = useTranslation();
  // const [loading, setLoading] = useState<boolean>();
  // const [success, setSuccess] = useState<boolean>();
  const [formData, setFormData] = useState<any>({
    name: '',
    email: '',
    amount: '',
  });

  const sendBtnHandler = () => {
    if(disableSendBtn) return;
    // setLoading(true);
    // registerDonation(formData, (_data: any, error: any) => {
    //   if (error) { alert(t('error-form')); setSuccess(false); }
    //   else setSuccess(true);
    //   setLoading(false);
    // });
  }

  const onchangeHandler = (event: any) => {
    const newData = { ...formData };
    newData[event.target.name] = event.target.value;
    setFormData(newData);
  }

  // const isValid = () => {
  //   return !formData.name && !formData.email;
  // }
  return (
    <>
      <section className="donation-section1">
        <div className="donation-section1-content">
          <div className='donation-section1-col1'>
            <Player autoplay loop src="/lotties/balloon.json" />
            <h1><Trans i18nKey={'donation-section1-title'} /></h1>
          </div>
          <div className='donation-section1-col2'>
            <h2><Trans i18nKey={'donation-section1-form-title'} /></h2>
            <p><Trans i18nKey={'donation-section1-form-desc'} /></p>

            <label><Trans i18nKey={'form-name'} />&nbsp;<span>(<Trans i18nKey={'form-mandatory'} />)</span></label>
            <input onChange={onchangeHandler} name="name" placeholder={t('form-name-placeholder')}/>
            <label><Trans i18nKey={'form-email'} />&nbsp;<span>(<Trans i18nKey={'form-mandatory'} />)</span></label>
            <input onChange={onchangeHandler} name="email" placeholder={t('form-email-placeholder')} />
            <label><Trans i18nKey={'form-amount'} /></label>
            <input onChange={onchangeHandler} name="amount" placeholder={t('form-amount-placeholder')} />
            <div className='donation-section1-btn-mention'>
              {/* <button disabled={isValid() || loading || success || disableSendBtn} onClick={sendBtnHandler} >{!success ? t('form-btn') :  t('form-btn-sent')}</button> */}
              <button disabled={disableSendBtn} onClick={sendBtnHandler} >{t('soon')}</button>
              <p><i><Trans i18nKey={'form-mention'} /></i></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Section1;
