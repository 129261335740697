import './MainLayout.scss';
import Header from './Header';
import Footer from './Footer';

function MainLayout(props: { children: any, className: string, hideImageBanner?: boolean }) {

  return (<>
    <Header className={`header__${props.className || ''}`} />
    <div id="main" className={props.className + '_main'} key={props.className}>
      <div className={`ks-mainFrame ${props.className || ''}`}>
        <div id='mainContentScreen' className='ks-content'>
          {props.children}
        </div>
      </div>
      <Footer className={`footer__${props.className || ''}`} />
    </div>
  </>);
}

export default MainLayout;
