import Section1 from 'sections/partner/Section1';

function Donation() {
  

  return (
    <>
      <Section1 />
    </>
  );
}

export default Donation;
