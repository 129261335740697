import { Player } from '@lottiefiles/react-lottie-player';
import { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { registerPartner } from 'services/EmailAPI';
import './Section1.scss';

function Section1() {
  const { t } = useTranslation();
  const lottieRef = useRef<any>();
  const [loading, setLoading] = useState<boolean>();
  const [success, setSuccess] = useState<boolean>();
  const [formData, setFormData] = useState<any>({
    name: '',
    email: '',
    message: '',
  });
  const hideEnvelope = () => {
    lottieRef.current.style.display = 'none';
  }
  const showEnvelope = () => {
    lottieRef.current.style.display = 'block'
  }

  const sendBtnHandler = () => {
    setLoading(true);
    registerPartner(formData, (data: any, error: any) => {
      if (error) { alert(t('error-form')); setSuccess(false); }
      else setSuccess(true);
      setLoading(false);
    });
  }

  const onchangeHandler = (event: any) => {
    const newData = { ...formData };
    newData[event.target.name] = event.target.value;
    setFormData(newData);
  }

  const isValid = () => {
    return !formData.name && !formData.email;
  }

  return (
    <>
      <section className="partner-section1">
        <div className="partner-section1-content">
          <h1><Trans i18nKey={'partner-title'} /></h1>
          <img src="/images/perso-prof.png" alt="Professeur" className='desktop'/>
          <div className='partner-section1-form'>
            <label><Trans i18nKey={'form-name'} />&nbsp;<span>(<Trans i18nKey={'form-mandatory'} />)</span></label>
            <input onChange={onchangeHandler} name='name' autoComplete='name' placeholder={t('form-name-placeholder')} value={formData.name} />
            <label><Trans i18nKey={'form-email'} />&nbsp;<span>(<Trans i18nKey={'form-mandatory'} />)</span></label>
            <input onChange={onchangeHandler} name='email' autoComplete='email' type="email" placeholder={t('form-email-placeholder')} value={formData.email} />
            <label><Trans i18nKey={'form-message'} /></label>
            <textarea onChange={onchangeHandler} name='message' placeholder={t('form-message-placeholder')} value={formData.message} onBlur={showEnvelope} onFocus={hideEnvelope} />
            <div className='messageLottie' ref={ref => lottieRef.current = ref}>
              <div className={success ? 'display' : 'hide'}><Player src="/lotties/message-sent.json" autoplay loop /></div>
              <div className={success ? 'hide' : 'display'}><Player src="/lotties/message.json" autoplay loop /></div>
            </div>
            <div className='partner-section1-btn-mention'>
              <button disabled={isValid() || loading || success} onClick={sendBtnHandler}>{!success ? t('form-btn') :  t('form-btn-sent')}</button>
              <p><i><Trans i18nKey={'form-mention'} /></i></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Section1;
