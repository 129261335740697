
import Partner from "page/Partner";
import Donation from "page/Donation";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import MainLayout from "./layout/MainLayout";
import Homepage from "./page/Homepage";
import ScrollToTop from "elements/ScrollToTop";

function App() {
  return (
    <Router >
      <ScrollToTop />
      <Switch>
        <Route path="/" exact={true} render={HomepageRender} />
        <Route path="/donation" exact={true} render={DonationRender} />
        <Route path="/partner" exact={true} render={PartnerRender} />
      </Switch>
    </Router>
  );
}

const HomepageRender = (props: any) => (
  <MainLayout key="homepage-page" className="homepage-page">
    <Homepage {...props} />
  </MainLayout>
);

const DonationRender = (props: any) => (
  <MainLayout key="donation-page" className="donation-page">
    <Donation {...props} />
  </MainLayout>
);

const PartnerRender = (props: any) => (
  <MainLayout key="partner-page" className="partner-page">
    <Partner {...props} />
  </MainLayout>
);

export default App;
