import { Player } from '@lottiefiles/react-lottie-player';
import { Trans, useTranslation } from 'react-i18next';
import './Section2.scss';

function Section2() {
  const { t } = useTranslation();

  return (
    <>
      <section className="donnation-section2">
        <div className="donnation-section2-frame">
          <div className="donnation-section2-col1">
            <h2>{t("donation-section2-title")}</h2>
            <p className='desktop'><Trans i18nKey={'donation-section2-desc'} /></p>
            <a className='desktop' href='/file/awlad-foundation.pdf' download>{t("donation-section2-download-btn")}</a>
          </div>

          <div className="donnation-section2-col2">
            <img src="/images/awlad-foundation.png" alt="Awlad Foundation" />
            <a href='/file/awlad-foundation.pdf' download><Player autoplay loop src="/lotties/download.json" /></a>
          </div>
          <p className='mobile'><Trans i18nKey={'donation-section2-desc'} /></p>
          <a className='mobile' href='/file/awlad-foundation.pdf' download>{t("donation-section2-download-btn")}</a>
        </div>
      </section>
    </>
  );
}

export default Section2;
