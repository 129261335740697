const apiBaseURL = process.env.REACT_APP_API_ENPOINT_BASE_URL;

export const registerPartner = (partnerData: any, callback: Function) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(partnerData)
  };
  fetch(apiBaseURL + '/partner', requestOptions)
    .then(response => response.json())
    .then(data => callback({ status: data.status }))
    .catch(err => callback(null, err));
}

export const registerDonation = (donationData: any, callback: Function) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(donationData)
  };
  fetch(apiBaseURL + '/donation', requestOptions)
    .then(response => response.json())
    .then(data => callback({ status: data.status }))
    .catch(err => callback(null, err));
}