import React, { useEffect, useRef, ReactNode } from "react";
import "./InfiniteSlider.scss";

interface InfiniteSliderProps {
  speed?: "fast" | "slow";
  direction?: "right" | "left";
  children: ReactNode;
}

export const InfiniteSlider: React.FC<InfiniteSliderProps> = ({
  children,
  speed = "fast",
  direction = "left",
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<Animation>();

  useEffect(() => {
    const slider = sliderRef.current;
    const sliderInner = slider?.querySelector(".slider__inner") as HTMLElement;

    if (!sliderInner) return;

    const calculateTotalWidth = () =>
      Array.from(sliderInner.children).reduce(
        (total, child) => total + (child as HTMLElement).offsetWidth,
        0
      );

    let totalWidth = calculateTotalWidth();

    const startAnimation = () => {
      const duration = speed === "fast" ? 20000 : 40000;
      const keyframes = [
        { transform: "translateX(0px)" },
        { transform: `translateX(-${totalWidth / 2}px)` },
      ];
      animationRef.current = sliderInner.animate(keyframes, {
        duration,
        iterations: Infinity,
        direction: direction === "left" ? "normal" : "reverse",
      });

      animationRef.current.onfinish = () => {
        totalWidth = calculateTotalWidth();
        startAnimation();
      };
    };

    startAnimation();

    const handleResize = () => {
      totalWidth = calculateTotalWidth();
      startAnimation();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      animationRef.current?.cancel();
    };
  }, [speed, direction, children]);

  return (
    <div
      className={`slider slider--${speed} slider--${direction}`}
      ref={sliderRef}
    >
      <div className="slider__inner">
        {children}
        {children}
      </div>
    </div>
  );
};
