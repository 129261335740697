import { Trans, useTranslation } from "react-i18next";
import "./VideoPresentation.scss";
import { Player } from "@lottiefiles/react-lottie-player";

export const VideoPresentation = () => {
  const { t } = useTranslation();

  return (
    <section className="video-presentation">
      <div className="video-presentation__container">
        <div className="video-presentation__title">
          <Player
            autoplay
            loop
            src="/lotties/light-bulb.json"
            className="video-presentation__title--anim"
          />
          <h2>
            <Trans i18nKey="video-presentation-title" />
          </h2>
        </div>
        <div className="video-presentation__vimeo">
          <iframe
            src={`https://player.vimeo.com/video/${t(
              "vimeo-link"
            )}?h=4e6c1061cb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            width="100%"
            height="100%"
            title="AWLAD School Pr&amp;eacute;sentation"
          ></iframe>
        </div>
      </div>
      <div className="video-presentation__awlad-boat">
        <div className="video-presentation__awlad-boat--boat video-presentation__awlad-boat--mobile">
          <Player autoplay loop src="/lotties/boat-paper.json" />
        </div>
        <div className="video-presentation__awlad-boat--boat video-presentation__awlad-boat--desktop">
          <Player autoplay loop src="/lotties/boat.json" />
        </div>
        <div className="video-presentation__awlad-boat--waves">
          <Player autoplay loop src="/lotties/waves.json" />
        </div>
      </div>
    </section>
  );
};
