import { Player } from "@lottiefiles/react-lottie-player";
import { useTranslation } from "react-i18next";
import "./HeroSection.scss";
import { StoresBtn } from "elements/StoresBtn";

export const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <div className="hero-section">
      <div
        aria-hidden="true"
        className="hero-section__image hero-section__image--awlad-swim"
      />
      <section className="hero-section__container">
        <img
          src="/images/iphone.svg"
          alt={t("alt-text-iphone")}
          className="hero-section__image hero-section__image--iphone"
        />
        {/* Mobile */}
        <div className="hero-section__content hero-section__content--mobile">
          <div
            aria-hidden="true"
            className="hero-section__image hero-section__image--logo"
          />
          <h1 className="hero-section__title">{t("homepage-title")}</h1>
        </div>
        {/* Desktop */}
        <div>
          <div className="hero-section__content hero-section__content--desktop">
            <div
              aria-hidden="true"
              className="hero-section__image hero-section__image--logo"
            />
            <h1 className="hero-section__title">{t("homepage-title")}</h1>
          </div>
          <div className="hero-section__info">
            <div className="hero-section__info--container">
              <div className="hero-section__free">
                <p className="hero-section__free--text">{t("homepage-free")}</p>
                <Player
                  autoplay
                  loop
                  src="/lotties/heart.json"
                  className="hero-section__free--heart"
                />
              </div>
              <p className="hero-section__desc">{t("homepage-desc")}</p>
            </div>
            <StoresBtn />
          </div>
        </div>
      </section>
    </div>
  );
};
