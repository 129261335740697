import { Player } from "@lottiefiles/react-lottie-player";
import Testimony from "elements/Testimony";
import { InfiniteSlider } from "elements/InfiniteSlider";
import { Trans, useTranslation } from "react-i18next";
import "./Testimonial.scss";
import { StoresBtn } from "elements/StoresBtn";

export const Testimonial = () => {
  const { t } = useTranslation();

  return (
    <section className="testimonial">
      <div className="testimonial__container">
        <h2 className="testimonial__title">
          <Trans i18nKey={"homepage-nb-subscriber"} />
        </h2>
        <div className="testimonial__ratings-container">
          <div className="testimonial__ratings">
            {[1, 2, 3, 4].map((int) => (
              <img src="/images/star.svg" alt="star" key={"star_" + int} />
            ))}
            <Player
              autoplay
              loop
              src="/lotties/favourite.json"
              className=" testimonial__favourite"
            />
          </div>
          <div className="testimonial__rating-text">
            <strong>4,9/5</strong>
            <div>
              <Trans i18nKey={"homepage-rating-text-more"} />
              <br />
              <p>
                <Trans i18nKey={"homepage-rating-text-number"} />
              </p>
            </div>
          </div>
        </div>
        <StoresBtn />
      </div>
      <InfiniteSlider>
        {[1, 2, 3, 4, 5].map((int) => (
          <div className="testimony" key={"testimony_" + int}>
            <Testimony
              testimonyI18nKey={"homepage-testimony" + int}
              studentI18nKey={"homepage-student" + int}
            />
          </div>
        ))}
      </InfiniteSlider>
    </section>
  );
};
