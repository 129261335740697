import './Header.scss';
import { useTranslation } from "react-i18next"
import { ReactComponent as AwladLogo } from 'assets/awlad/logo.svg';
import { ReactComponent as LeftArrow } from 'assets/awlad/left-arrow.svg';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

function Header(props: { className?: string }) {
  const { className } = props;
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState<string>();
  const [isMenuOpen, setMenuIsOpen] = useState<boolean>();

  const changeLanguageHandler = (event: any) => {
    i18n.changeLanguage(event.target.value.toLowerCase());
    setLanguage(event.target.value.toLowerCase())
  }
  useEffect(() => {
    setLanguage(i18n.resolvedLanguage)
  }, [i18n.resolvedLanguage]);
  return (
    <>
      <header className={className || ''}>
        <nav>
          <div className="burger-wrapper">
            <div className={(isMenuOpen && "open ") + " burger burger-rotate"} onClick={() => setMenuIsOpen(prev => !prev)}>
              <div className="burger-lines"></div>
            </div>
          </div>
          <Link to='/' className='logo'><AwladLogo /></Link>
          <div className={(isMenuOpen && "open ") + ' links'}>
            <Link to='/' className='homepage'>{t('welcome')}</Link>
            <Link to='/donation' className='donation'>{t('make-donation')}</Link>
            <Link to='/partner' className='partner'>{t('become-partner')}</Link>
            <a href='/#contest' className='mobile' style={{display: 'none'}}>{t('contest-link')}</a>
            <a href='/#contest' className='contest-link desktop' style={{display: 'none'}}><img src='/images/contest_logo.svg' alt='Contest Logo' /></a>
          </div>
          <div className='switch-language'>
            <select onChange={changeLanguageHandler} value={language}>
              <option value={'fr'}>FR</option>
              <option value={'en'}>EN</option>
            </select>
            <LeftArrow />
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
