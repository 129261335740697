import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./LearnMore.scss";

export const LearnMore = () => {
  const { t } = useTranslation();

  return (
    <section className="learn-more">
      <div className="learn-more__container">
        <h2 className="learn-more__title">
          <Trans i18nKey="homepage-learn-more-title" />
        </h2>
        <div className="learn-more__frame">
          <img
            src="/images/section6-img.jpg"
            alt={t("alt-text-learn-more-section-image")}
          />
          <div className="learn-more__frame--content">
            <p>
              <Trans i18nKey="homepage-learn-more-desc" />
            </p>
            <Link to="/donation" className="learn-more__btn">
              {t("learn-more-btn")}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
