import { Trans, useTranslation } from "react-i18next";
import "./Features.scss";
import { Player } from "@lottiefiles/react-lottie-player";
import { StoresBtn } from "elements/StoresBtn";
import { useResponsive } from "hooks/useResponsive";

export const Features = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  return (
    <section className="features">
      {isMobile ? (
        <div className="features__container">
          <div className="features__title">
            <Player
              autoplay
              loop
              src="/lotties/light-bulb.json"
              className="features__title--anim"
            />
            <Trans i18nKey={"features-title"} />
          </div>

          {/* Feature 1 */}
          <div className="features__item">
            <div className="features__illustration">
              <img src="/images/features-1-desktop.svg" alt="iphone" />
            </div>
            <p className="features__item--text1">
              <Trans i18nKey={"features-item1-mobile"} />
            </p>
            <Player
              autoplay
              loop
              src="/lotties/sub-01.json"
              className="features__item--sub1"
            />
          </div>

          {/* Feature 2 */}
          <div className="features__item">
            <p className="features__item--text2">
              <Trans i18nKey={"features-item2-mobile"} />
            </p>
            <div className="features__illustration features__illustration--2">
              <img src="/images/features-2-desktop.svg" alt="iphone" />
            </div>
          </div>

          {/* Feature 3 */}
          <div className="features__item features__item--items3">
            <p className="features__item--text3">
              <Trans i18nKey={"features-item3-mobile"} /> <br />
              <Trans i18nKey={"features-item3-desktop"} />
            </p>
            <div className="features__illustration-3">
              <img src="/images/features-3.svg" alt="iphone" />
            </div>
          </div>
          <div className="features__free">
            <p className="features__free--text">{t("homepage-free")}</p>
            <Player
              autoplay
              loop
              src="/lotties/heart.json"
              className="features__free--heart"
            />
          </div>
          <StoresBtn />
        </div>
      ) : (
        <div className="features__container">
          <div className="features__title">
            <Trans i18nKey={"features-title"} />
          </div>

          {/* Feature 1 */}
          <div className="features__item">
            <p className="features__item--text1-desktop">
              <Trans i18nKey={"features-item1-desktop"} />
            </p>
            <div className="features__illustration">
              <img src="/images/features-1-desktop.svg" alt="iphone" />
            </div>
            <p className="features__item--text1">
              <Trans i18nKey={"features-item1-mobile"} />
            </p>
            <Player
              autoplay
              loop
              src="/lotties/sub-01.json"
              className="features__item--sub1"
            />
          </div>

          {/* Feature 2 */}
          <div className="features__item">
            <p className="features__item--text2-desktop">
              <Trans i18nKey={"features-item2-desktop"} />
            </p>
            <div className="features__illustration features__illustration--2">
              <img src="/images/features-2-desktop.svg" alt="iphone" />
            </div>
            <p className="features__item--text2">
              <Trans i18nKey={"features-item2-mobile"} />
            </p>
          </div>

          {/* Feature 3 */}
          <div className="features__item features__item--items3">
            <p className="features__item--text3-desktop">
              ...
              <Trans i18nKey={"features-item3-desktop"} />
            </p>
            <div className="features__illustration-3">
              <img src="/images/features-3.svg" alt="iphone" />
            </div>
            <p className="features__item--text3">
              <Trans i18nKey={"features-item3-mobile"} />
            </p>
            <Player
              autoplay
              loop
              src="/lotties/sub-02.json"
              className="features__item--sub2"
            />
          </div>
        </div>
      )}

      <div className="features__illustration-city" />
    </section>
  );
};
