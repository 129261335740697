import Section1 from 'sections/donation/Section1';
import Section2 from 'sections/donation/Section2';

function Donation() {
  

  return (
    <>
      <Section1 />
      <Section2 />
    </>
  );
}

export default Donation;
