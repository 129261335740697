import { Features } from "sections/homepage/Features";
import { HeroSection } from "sections/homepage/HeroSection";
import { LearnMore } from "sections/homepage/LearnMore";
import { Testimonial } from "sections/homepage/Testimonial";
import { VideoPresentation } from "sections/homepage/VideoPresentation";

function Homepage() {
  return (
    <>
      <HeroSection />
      <VideoPresentation />
      <Testimonial />
      <Features />
      <LearnMore />
    </>
  );
}

export default Homepage;
